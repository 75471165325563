import {applyMiddleware, createStore, compose} from 'redux'
import {rootReducer} from './reducers/index'
import reduxPromise from "redux-promise-middleware"

const middleware = [reduxPromise]

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const store = createStore(
    rootReducer,
    composeEnhancers( applyMiddleware(...middleware) )
);

export default store