import _ from 'lodash'
import {v1 as uuidv1} from 'uuid';
export let labelData_copy;

const getLabelDataCopy = () => {
    return labelData_copy;
}

const setLabelDataCopy = (copy) => {
    labelData_copy = _.cloneDeep(copy)
}
const getProjectId = () => {
    const pathCells = window.location.pathname.split('/')
    if (pathCells[1] === 'project') return pathCells[2]
    else return null
}

const ocrEnable = {
    text: true,
    handwritten: true,
    signature: true,
    checkbox: true,
    address: true,
    datetime: true,
    numeric: true,
    qrcode: true,
    barcode: true,
    logo: false,
    stamp: false,
    image: false,
};

const colors = [
    '#ff8c31',
    '#38b48b',
    '#bc64a4',
    '#00bc12',
    '#db5a6b',
    '#8d4bbb',
    '#89c3eb',
    '#d7cf3a',
    '#2ca9e1',
    '#177cb0',
    '#e29c45',
    '#98d98e',
    '#e9546b',
    '#c89b40',
    '#ee827c',
    '#d69090',
    '#83ccd2',
    '#b0a4e3',
    '#edd1d8',
    '#1bd1a5',
    '#00e079',
    '#4a4266',
    '#ff7500',
    '#44cef6',
    '#ff4e20',
    '#c83c23',
    '#88ada6',
    '#3eede7',
    '#00bc12',
    '#fff143',
]

const shortcuts = '1234567890qwertyuiopasdghjklzxvbnm'

const genId = () => {
    return uuidv1();
}

export {getProjectId, ocrEnable, getLabelDataCopy, setLabelDataCopy, colors, shortcuts, genId}
