const CurrentImageReducer = (state = {}, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_CURRENT_IMAGE':
            return payload
        default:
            return state
    }
}

const imagesReducer = (state = [], action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_IMAGES':
            return payload
        default:
            return state
    }
}

const imageIndexesReducer = (state = {}, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_IMAGE_INDEXS':
            return payload
        default:
            return state
    }
}

const pageReducer = (state = 1, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_PAGE':
            return payload
        default:
            return state
    }
}

const unlabeledOnlyReducer = (state=false, action) => {
    const { type, payload } = action
    switch(type) {
        case 'SWITCH_UNLABELED_ONLY':
            return payload
        default:
            return state
    }
}

const autoSplitReducer = (state=true, action) => {
    const { type, payload } = action
    switch(type) {
        case 'SWITCH_AUTO_SPLIT':
            return payload
        default:
            return state
    }
}

const selectedKeysReducer = (state = [], action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_KEYS':
            return payload
        default:
            return state
    }
}

const expandedKeysReducer = (state = [], action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_EXPANDED_KEYS':
            return payload
        default:
            return state
    }
}

const selectedFigureIdReducer = (state = -1, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_SELECTED_FIGURE_ID':
            return payload
        default:
            return state
    }
}

const figuresReducer = (state = [], action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_FIGURES':
            return payload
        default:
            return state
    }
}

const sizeReducer = (state = 1024, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_SIZE':
            return payload
        default:
            return state
    }
}

const clockwiseAndAngleReducer = (state = {clockwise: false, angle: -1}, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_CLOCKWISE_ANGLE':
            return payload
        default:
            return state
    }
}

const labelDataReducer = (state = [], action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_LABEL_DATA':
            return payload
        default:
            return state
    }
}

const unfinishedFigureReducer = (state = null, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_UNFINISHED_FIGURE':
            return payload
        default:
            return state
    }
}

const selectLabelIdReducer = (state = -1, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_SELECTED_LABEL_ID':
            return payload
        default:
            return state
    }
}

const inferenceFunReducer = (state = {}, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_INFERENCE_FUN':
            return payload
        default:
            return state
    }
}

const treeDataReducer = (state = [], action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_TREE_DATA':
            return payload
        default:
            return state
    }
}

const labeledReducer = (state = 0, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_LABELED':
            return payload
        default:
            return state
    }
}

const initLabelDataReducer = (state = [], action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_INIT_LABEL_DATA':
            return payload
        default:
            return state
    }
}

const submitLoadingReducer = (state = {isLoading: false, from: 0}, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_SUBMIT_LOADING':
            return payload
        default:
            return state
    }
}

const leftCollapsedReducer = (state = false, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SWITCH_LEFT_COLLAPSED':
            return payload
        default:
            return state
    }
}

const rightCollapsedReducer = (state = false, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SWITCH_RIGHT_COLLAPSED':
            return payload
        default:
            return state
    }
}

export {CurrentImageReducer, imagesReducer, imageIndexesReducer, figuresReducer, sizeReducer, labelDataReducer, leftCollapsedReducer,
    pageReducer, unlabeledOnlyReducer, autoSplitReducer, selectedKeysReducer, expandedKeysReducer, selectedFigureIdReducer, clockwiseAndAngleReducer, unfinishedFigureReducer,
    selectLabelIdReducer, inferenceFunReducer, treeDataReducer, labeledReducer, initLabelDataReducer, submitLoadingReducer,
    rightCollapsedReducer}