import axios from "axios";

axios.defaults.withCredentials = true;

const getProjects = () => {
    return axios.get('/api/projects/');
};

const createProject = (projectName, description) => {
    return axios.post('/api/new_project/', {name: projectName, description: description});
};

const createBatchProject = (projectId, data) => {
    return axios.post(`/api/project_batch_create/${projectId}/`, data);
};

const updateProject = (projectId, projectName, description, status) => {
    if (projectName === ""  && description === "" && status === "") {
        return axios.delete(`/api/edit_project/${projectId}/`)
    }
    return axios.put(`/api/edit_project/${projectId}/`, {name: projectName, description: description, status: status});
}

const getProject = (id) => {
    return axios.get(`/api/project_info/${id}/`)
}

const getCount = (id) => {
    return axios.get(`/api/analysis/class_counts/${id}/?unclassified=True`)
}

const getProjectJson = (projectId) => {
    return axios.get(`/api/export_project_json/${projectId}/?page_pdf=True`)
}

const createBatchClass = (projectId, data) => {
    return axios.post(`/api/class_batch_create/${projectId}/`, data)
}

const projectServices = {getProjects, createProject, createBatchProject, updateProject, getProject, getCount, getProjectJson, createBatchClass};

export default projectServices;
