import { combineReducers } from 'redux'
import { AuthReducer } from './Auth'
import { ProjectsReducer, ProjectReducer, CollapsedReducer, TaskReducer } from './Project'
import { ImageReducer } from './Image'
import { ClassReducer, selectedClassIdReducer, selectedClassNameReducer } from './Class'
import { ResultsReducer, sheet1Reducer, sheet2Reducer } from './Inference'
import { SchemaReducer } from "./Schema"
import { FileReducer } from './File'
import {CurrentImageReducer, imagesReducer, imageIndexesReducer, pageReducer, unlabeledOnlyReducer, autoSplitReducer, labelDataReducer, unfinishedFigureReducer,
  selectedKeysReducer, expandedKeysReducer, selectedFigureIdReducer, figuresReducer, sizeReducer, clockwiseAndAngleReducer,
  selectLabelIdReducer, inferenceFunReducer, treeDataReducer, labeledReducer, initLabelDataReducer, submitLoadingReducer,
  leftCollapsedReducer, rightCollapsedReducer} from './Label'
import {CurModelTabKeyReducer, RegressionDetailsReducer, EvaluationPreviewDataReducer, EvaluationPreviewModalReducer,
  EvaluationPreviewImageIDReducer, AssignedModelReducer} from './Model'

const rootReducer = combineReducers({
  auth: AuthReducer,
  projects: ProjectsReducer,
  image: ImageReducer,
  class: ClassReducer,
  selectedClassId: selectedClassIdReducer,
  selectedClassName: selectedClassNameReducer,
  project: ProjectReducer,
  schema: SchemaReducer,
  results: ResultsReducer,
  results_grouped_1: sheet1Reducer,
  results_grouped_2: sheet2Reducer,
  collapsed: CollapsedReducer,
  currentImage: CurrentImageReducer,
  images: imagesReducer,
  imageIndexes: imageIndexesReducer,
  page: pageReducer,
  unlabeledOnly: unlabeledOnlyReducer,
  autoSplit: autoSplitReducer,
  selectedKeys: selectedKeysReducer,
  expandedKeys: expandedKeysReducer,
  selectedFigureId: selectedFigureIdReducer,
  figures: figuresReducer,
  size: sizeReducer,
  clockwiseAndAngle: clockwiseAndAngleReducer,
  labelData: labelDataReducer,
  unfinishedFigure: unfinishedFigureReducer,
  selectLabelId: selectLabelIdReducer,
  inferenceFun: inferenceFunReducer,
  treeData: treeDataReducer,
  labeled: labeledReducer,
  files: FileReducer,
  initLabelData: initLabelDataReducer,
  submitLoading: submitLoadingReducer,
  leftCollapsed: leftCollapsedReducer,
  rightCollapsed: rightCollapsedReducer,
  task: TaskReducer,
  curModelTabKey: CurModelTabKeyReducer,
  regressionDetails: RegressionDetailsReducer,
  evalPreviewData: EvaluationPreviewDataReducer,
  evalPreviewModal: EvaluationPreviewModalReducer,
  evalPreviewImageId: EvaluationPreviewImageIDReducer,
  assignedModelInfo: AssignedModelReducer
})

export { rootReducer }
