const ResultsReducer = (state = [], action) => {
    const {type, payload} = action
    switch (type) {
        case 'RESULTS_SET':
            return payload
        default:
            return state
    }
};

const sheet1Reducer = (state = [], action) => {
    switch (action.type) {
      case 'SHEET1_RESULTS':
        return action.payload;
      default:
        return state;
    }
  };

  const sheet2Reducer = (state = [], action) => {
    switch (action.type) {
      case 'SHEET2_RESULTS':
        return action.payload;
      default:
        return state;
    }
  };

export {ResultsReducer, sheet1Reducer, sheet2Reducer}