const initialState = {
    allFiles: []
};

const FileReducer = (state = initialState, action) => {
    const {type} = action;

    switch (type) {
        case 'ADD_FILE':
            return {...state, allFiles: [...state.allFiles, action.file]}
        case 'REMOVE_FILE':
            return {...state, allFiles: state.allFiles.filter(file => file.uid !== action.file.uid)}
        case 'REMOVE_ALL_FILES':
            return {...state, allFiles: []}
        default:
            return state
    }
}

export {FileReducer};