const CurModelTabKeyReducer = (state = "detection", action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_CURRENT_MODEL_TAB':
            return payload
        default:
            return state
    }
}

const RegressionDetailsReducer = (state = false, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_REGRESSION_DETAIL':
            return payload
        default:
            return state
    }
}

const EvaluationPreviewDataReducer = (state = {}, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_EVALUATION_PREVIEW_DATA':
            return payload
        default:
            return state
    }
}

const EvaluationPreviewModalReducer = (state = false, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_EVALUATION_PREVIEW_MODAL':
            return payload
        default:
            return state
    }
}

const EvaluationPreviewImageIDReducer = (state = -1, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_EVALUATION_PREVIEW_IMAGE_ID':
            return payload
        default:
            return state
    }
}

const AssignedModelReducer = (state, action) => {
    const {type, payload} = action
    switch (type) {
        case 'GET_ASSIGNED_MODEL_INFO':
            return payload
        default:
            return {
                "project_id":-1,
                "class_id":-1,
                "assigned_model_id": -1,
                "assigned_model_name":"",
                "active_version":""
                }
    }

}

export {CurModelTabKeyReducer, RegressionDetailsReducer, EvaluationPreviewDataReducer, EvaluationPreviewModalReducer, EvaluationPreviewImageIDReducer, AssignedModelReducer}