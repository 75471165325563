const initialState = {
    classData: {
        data: {list: []},
        status: '',
        isPending: false,
    },

    subclassData: {
        data: {list: []},
        status: '',
        isPending: false,
    }
};

const ClassReducer = (state = initialState, action) => {
    const {type, payload} = action;

    switch (type) {
        case 'GET_CLASS_DATA_PENDING': {
            const classData = {
                ...state.classData,
                data: payload,
                status: '',
                isPending: true,
            }
            return {...state, classData};
        }

        case 'GET_CLASS_DATA_FULFILLED': {
            const classData = {
                ...state.classData,
                data: payload,
                status: '',
                isPending: false,
            }
            return {...state, classData};
        }

        case 'GET_SUBCLASS_DATA_PENDING': {
            const subclassData = {
                ...state.subclassData,
                data: payload,
                status: '',
                isPending: true,
            }
            return {...state, subclassData};
        }

        case 'GET_SUBCLASS_DATA_FULFILLED': {
            const subclassData = {
                ...state.subclassData,
                data: payload,
                status: '',
                isPending: false,
            }
            return {...state, subclassData};
        }

        default:
            return state;
    }
};


const selectedClassIdReducer = (state = -1, action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_CURRENT_CLASS_ID':
            return payload
        default:
            return state
    }
};

const selectedClassNameReducer = (state = '', action) => {
    const { type, payload } = action
    switch (type) {
        case 'SET_CURRENT_CLASS_NAME':
            return payload
        default:
            return state
    }
};

export {ClassReducer, selectedClassIdReducer, selectedClassNameReducer};
