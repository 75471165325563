import React from 'react';
const AdminApp = React.lazy(() => import('../views/admin/AdminApp'));
const Project = React.lazy(() => import('../views/project/Project'));
const Login = React.lazy(() => import('../views/home/Login'));
const UserProfile = React.lazy(() => import('../views/User-Management/User-Profile'));
const UserManagement = React.lazy(() => import('../views/User-Management/User-Management'));

const privateRoutes = [
    {
        path: '/',
        component: [Login, AdminApp],
        exact: true,
    },
    {
        path: '/project',
        component: Project,
        exact: false,
        backUrl: '/',
    },
    {
        path: '/user-profile',
        component: UserProfile,
        exact: true,
        backUrl: '/',
    },
    {
        path: '/management',
        component: UserManagement,
        exact: false,
        backUrl: '/',
    }
];

export default privateRoutes;
