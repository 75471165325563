import React, {Suspense, useEffect} from 'react';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.css';
import {Route, Switch, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import privateRoutes from './routes/privateRoutes';
import AuthRoute from './routes/AuthRoute';
import publicRoutes from './routes/publicRoutes';
import _ from "lodash";
import ProjectAPI from "./services/Project";
import projectActions from "./store/action/ProjectActions";
import './theme.less'
import taskServices from "./services/Task";


function App() {
    const {location: {pathname}} = useHistory()
    const dispatch = useDispatch()

    const project = useSelector(state => state.project, _.isEqual)
    const task = useSelector(state => state.task, _.isEqual)
    useEffect(() => {
        async function fetchMyAPI(){
            const pathCells = pathname.split('/')
            if (pathCells[1] === 'project' && !project.name) {
                const project = ProjectAPI.getProject(pathCells[2])
                dispatch(projectActions.getProject(project))
            }
            if (pathCells[3] === 'task' && !task.pk) {
                const task_response = await taskServices.getTasks();
                if (task_response.success) {
                    task_response.list.forEach(item => {
                        if (item.pk.toString() === pathCells[4]) {
                            dispatch(projectActions.setTask(item))
                        }
                    })
    
                }
            }
        }
        fetchMyAPI();
    })

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Switch>
                {publicRoutes.map(({path, component, ...routes}) => (
                    <Route
                        key={path}
                        path={path}
                        component={component}
                        {...routes}
                    />
                ))}
                {privateRoutes.map(route => (
                    <AuthRoute key={route.path} {...route} />
                ))}
            </Switch>
        </Suspense>
    );
}

export default App;
